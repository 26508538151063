<template>
    <div class="message-list-box">
    
        <div class="message-list-mssage" ref="messagebox">
          <div ref="messageboxitem">
            <div 
                v-for="(item,index) in listData" 
                :key="index" 
                >
                <div class="message-list-item"  :class="{'message-list-item-right':item.messageDirection == 1}"  >
                  
                  <img src="./logo.png" alt="message-list-item-head" class="conversation-list-head" />
                  <div class="conversation-list-msg">
                   
                      <!-- <div class="conversation-list-msg-title">{{item.senderUserId}}</div> -->
                       <!-- 文字消息 -->
                      <div class="conversation-list-msg-text" v-if="item.messageType == 'RC:TxtMsg'">{{ item.content.content}}</div>
                      <!-- 图片消息 -->
                      <div class="" v-if="item.messageType == 'RC:ImgMsg'">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->
                          <!-- {{ item.content.imageUri }} -->
                          <img 
                              class="conversation-list-msg-image"
                              :src="item.content.imageUri || item.content.content" 
                              :preview-src-list="[item.content.imageUri || item.content.content]">
                            </img>
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                      <!-- 视频消息 -->
                      <div class="video-msg-box" v-if="item.messageType == 'RC:SightMsg'" @click="onPlayVideo(item.content)">
                          <!-- <img :src="item.content.imageUri || item.content.content" alt="message-list-item-head" class="conversation-list-msg-image"/> -->

                          <img 
                              class="conversation-list-msg-image"
                              src="./video.jpg" >
                            </img>
                            <!-- <video class="conversation-list-msg-image" :src="item.content.sightUrl"></video> -->
                            <i class=" el-icon-video-play  play-icon"></i>
                           
                          <!-- <img :src="item.content.content" alt="message-list-item-head" /> -->
                      </div>
                      <!-- 自定义消息  商品链接 -->

                      <!-- 自定义消息  订单链接 -->
                       
                  </div>
                </div>
                <!-- {{ item }} -->
               
            </div>
          </div>
            
        </div>
        
        <a-modal
              v-model="dialogVisible"
              :destroyOnClose="true"
              :footer="null"
              :closable="true"
               style="text-align: center; ">
               <!-- {{ dialogVisible }} -->
          <!-- <player :video="video" v-if="playerVisible"></player> -->
              <video ref="videoPlayer" v-if="videoUrl && dialogVisible" class="video-item" controls @click="onplay">
                <source :src="videoUrl" type="video/mp4">
              </video>
        </a-modal>


    </div>
  </template>
  
  <script>
   const RongIMLib = require('@rongcloud/imlib-next')
  export default {
    name: 'ConversationList',
    props: {
      msg: String,
      msgArry: Array,
      selectId: String,
      user:String,
    },
    data() {
      return {
        listData:[],
        hasMore:true,
        dialogVisible: false,
        videoUrl:''
      };
    },
    watch: {
      selectId: {
      handler(newVal, oldVal) {
        console.log(newVal,oldVal)
        this.getHitoryList()
      },
      deep: true
    }
  },
    methods: {
      getHitoryList(){
        console.log('获取历史记录')
        let that = this;
        const conversation = {
            targetId: this.selectId,
            conversationType: RongIMLib.ConversationType.PRIVATE
          };
          const option = {
            // 获取历史消息的时间戳，默认为 0，表示从当前时间获取
            timestamp: +new Date(),
            // 获取条数，有效值 1-100，默认为 20
            count: 20,
          };
          RongIMLib.getHistoryMessages(conversation, option).then(result => {
            console.log('获取历史消息成功', result);
            that.listData = result.data.list;       // 获取到的消息列表
            console.log('获取历史消息成功', result);
            const hasMore = result.data.hasMore; // 是否还有历史消息可获取
            that.hasMore = hasMore
            // console.log('获取历史消息成功', list, hasMore);
        // that.scrollBottom()
        setTimeout(()=>{
          that.scrollBottom()
        },300)
          }).catch(error => {
            console.log('获取历史消息失败', error,error.code, error.msg);
          });
      },

      upDataList(data){
        console.log('子集更新list',data)
        let that = this;
        that.listData.push(data)
       
        setTimeout(()=>{
          that.scrollBottom()
        },300)

      },
   
	
      scrollBottom(){
        let that = this;
        this.$nextTick(()=>{
          that.$refs.messagebox.scrollTop = that.$refs.messageboxitem.scrollHeight 
        })
        
      },

      onPlayVideo(item){
        this.videoUrl = ''
        this.videoUrl = item.sightUrl;
        this.dialogVisible = true
      },

      onplay(){
        this.$refs.videoPlayer.play(); 
      }
    }

 
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style  scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .message-list-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eee;
    background-color: #fff;
  }
  .message-list-head{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
  }
  .message-list-item{
    display: flex;
    padding: 16px;
   }
  .message-list-item-right{
    display: flex;
    /* justify-content: flex-end; */
    flex-direction: row-reverse;
  }
  .message-list-item-right .conversation-list-msg{
    background-color: #ff953e;
    color: #fff;
    border-radius: 18px 0px  18px 18px;
  }
  .message-list-item-head{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #eee;
  }
  .conversation-list-item{
    display: flex;
    padding: 16px;
    background-color: #e5e4e4;
    border-bottom: 1px solid #eee;
  }

  .message-list-mssage{
      flex: 1;
      overflow-y: auto;
      
  }

.message-list-mssage::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}
.message-list-mssage::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}
.message-list-mssage::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}
.message-list-mssage::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.message-list-mssage::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

  .messageboxitem{
    transition: all 1s ease;
  }
  .active{
    background-color: #ff953e;
  }
  .conversation-list-head{
    width: 50px;
    height: 50px;
    border: 1px solid #eee;
    border-radius: 50%;
  } 
  .conversation-list-msg{
    /* flex: 1; */
    text-align: left;
    margin:0 16px;
    background-color: #eee;
    padding: 0 10px;
    border-radius: 10px;
    max-width: 80%;
    word-break: break-all;
    border-radius: 0px 18px  18px 18px;
  }
  .conversation-list-msg-text{
    line-height: 50px;

  }
  .conversation-list-msg-title{
    font-weight: bold;
    margin-bottom: 6px;
  }
  .conversation-list-msg-image{
    margin:10px 0;
    width: 120px;
    display: block;
  }
  .video-item{
    max-width: 600px;
    max-height: 600px;
  }
  .video-msg-box{
    position: relative;
  }
  .play-icon{
    position: absolute;
    top: calc(50% - 25px) ;
    left: calc(50% - 25px) ;
    color: #fff;
    font-size: 50px;
  }
  </style>
  