<template>
    <div class="conversation-list-box">
      <div v-if="showTops" style="display: flex;align-items: center;margin-bottom: 10px;">
        <img :src="myUserInfo && myUserInfo.avatar" alt="message-list-item-head" class="conversation-list-heads" />
        <div style="color: #333;margin-left: 12px;font-weight: bold;">{{myUserInfo && myUserInfo.nickName}}</div>
      </div>
      <div v-if="showTops" style="background: #FF953E;color: #fff;height:35px;line-height: 35px;text-align: center;border: 1px solid #eee;border-right: 0;border-left: 0">
        当前会话
      </div>
<!--      :class="{'active':item.targetId == selectId}"-->
        <div class="conversation-list-item"
            v-for="(item,index) in msgArry"
            :key="index"
             :style="{backgroundColor: item.targetId == selectId ? '#ff953e' : item.isTop && showTops ? '#999' : '#fff'}"
            @click="onChangeUser(item)"
            >
            <!-- {{ item }} -->
            <img :src="item.avatar" alt="" class="conversation-list-head">
            <div class="conversation-list-msg">
              <div v-if='item.isTop && showTops' style="display: flex;align-items: center;justify-content: flex-end;">
                <div  style="background: #FF953E;font-size: 12px;border-radius: 6px;width: 40px;height: 20px;text-align: center;line-height: 18px;color: #fff;border: 1px solid #fff">置顶</div>
              </div>
                <div class="conversation-list-msg-title">{{item.remark || item.nickName}}</div>
                <!-- <div class="conversation-list-msg-text">{{ item.msg }}</div> -->
                <div class="conversation-list-msg-tag" v-if="item.unreadMessageCount">{{ item.unreadMessageCount }}</div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  // const RongIMLib = require('@rongcloud/imlib-next')
  export default {
    name: 'ConversationList',
    props: {
      msg: String,
      msgArry: Array,
      selectId: String,
      myUserInfo: Object,
      showTops: {
        type: Boolean,
        default: true
      }


    },

    methods: {
      onChangeUser(item){
        console.log(item.targetId == this.selectId);
        if(item.targetId == this.selectId) return
        this.$emit("changeItem", item);
      } 
      // onChangeUser
      // getList(){

      // }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .conversation-list-box{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #c3c3c3;
    background-color: #fff;
  }

  .conversation-list-item{
    display: flex;
    padding: 16px;
    background-color: #c3c3c3;
    border-bottom: 1px solid #eee;
  }
  .active{
    background-color: #ff953e;
  }
  .conversation-list-head{
    width: 50px;
    height: 50px;
  }
  .conversation-list-heads{
    width: 50px;
    height: 50px;
    border: 1px solid #FF953E;
    border-radius: 50%;
  }
  .conversation-list-msg{
    flex: 1;
    text-align: left;
    margin-left: 16px;
  }
  .conversation-list-msg-title{
    font-weight: bold;
    margin-bottom: 6px;
  }
  .conversation-list-msg-tag{
    background-color: #f00;
    width: auto;
    height: 20px;
    border-radius: 20px;
    padding: 0 6px;
    display: inline-block;
    color: #fff;
  }
  </style>
  